import React, { useState } from "react";
import TextBox from "../../shared/controls/TextBox";
import { produce } from "immer";
import { Button } from "primereact/button";

const defaultFilter = {};

const PerfilFiltro = ({ filtro, onChange, onSearch }) => {
    const [filter, setFilter] = useState(defaultFilter);

    const actualizar = (value, name) => {
        const newFilter = produce(filter, draft => {
            draft[name] = value;
        });

        setFilter(newFilter);

        if (value) {
            const newValue = produce(filtro, draft => {
                draft[name] = value;
            });

            onChange(newValue);
        }
        else {
            let newFiltro = { ...filtro };
            delete newFiltro[name];
            onChange({ ...newFiltro });
        }
    };

    const limpiar = () => {
        setFilter({ ...defaultFilter, folio: '', proyecto: '' });
        const filtroInicial = {};

        onChange({ ...filtroInicial })
    };

    return (
        <div className="row">
            <TextBox name={'name'} value={filtro?.name} lg={3} md={3} sm={12}
                onChange={actualizar} placeholder={'Nombre Perfil'} />
            <div className="form-group col-lg-9 col-md-9 col-sm-12" style={{ textAlign: 'end' }}>
                <Button icon="pi pi-filter" className="m-1 p-button-secondary" onClick={limpiar} />
                <Button icon="pi pi-search" className="m-1" onClick={onSearch} />
            </div>
        </div>
    );
};

export default PerfilFiltro;