/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import moment from "moment";

const CalendarRangeBox = ({ name, label, value, onChange, lg, md, sm, requerido, minDate, maxDate, disabled }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    const change = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setSelectedValue(e.target.value);
        onChange(value, name);
    }

    useEffect(() => {
        if (value != null && selectedValue != null && value.length >= 2 && selectedValue.length >= 2 && value[0] === selectedValue[0] && value[1] === selectedValue[1]) {
            return;
        }

        if (value == null) {
            setSelectedValue(value);
        } else {
            setSelectedValue(value.map(e => moment(e)._d));
        }
    }, [value]);

    return (
        <div className={`form-group col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column">
                <label htmlFor={name} className='etiquetas'>
                    {requerido &&
                        <span style={{ color: 'red' }}>* </span>
                    }
                    {label}
                </label>
                <Calendar id={name} name={name} value={selectedValue} onChange={change} dateFormat="dd/M/yy" disabled={disabled} locale={'es'}
                    yearRange="1950:2100" selectionMode="range" readOnlyInput
                    className={requerido && ((value ?? null) === null) ? 'p-invalid block' : ''} minDate={minDate} maxDate={maxDate} />
            </div>
        </div>
    );
};

export default CalendarRangeBox;