import React from "react";
import TextBox from "../shared/controls/TextBox";
import CalendarBox from "../shared/controls/CalendarBox";
import { Constantes } from "../shared/Constantes";

const General = ({ entity, actualizar }) => {
    return (
        <div className="row py-1">
            <TextBox label={'PO Number'} name={'poNumber'} value={entity?.poNumber} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Retailer Code'} name={'retailerCode'} value={entity?.retailerCode} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Customer Order'} name={'customerOrder'} value={entity?.customerOrder} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Status'} name={'status'} value={entity?.status} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Exception'} name={'exception'} value={entity?.exception} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Ecomm Delivery Method'} name={'eCommDeliveryMethod'} value={entity?.eCommDeliveryMethod} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <CalendarBox label={'EComm Date'} name={'eCommDate'} value={entity?.eCommDate} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <CalendarBox label={'Shipping Deadline'} name={'shippingDeadline'} value={entity?.shippingDeadline} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <CalendarBox label={'ImportTime'} name={'importTime'} value={entity?.importTime} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Tracking No.'} name={'trackingNo'} value={entity?.trackingNo} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={entity?.estatusId !== Constantes.status.Shipped} disabled={entity?.estatusId === Constantes.status.Shipped} />
            <TextBox label={'Shipping Method'} name={'shippingMethod'} value={entity?.shippingMethod} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={entity?.estatusId !== Constantes.status.Shipped} disabled={entity?.estatusId === Constantes.status.Shipped} />
        </div>
    );
};

export default General;