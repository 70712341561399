import 'bootstrap/dist/css/bootstrap.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nano/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './App.css';
import React from 'react';
import moment from 'moment/moment';
import { addLocale } from "primereact/api";
import datePickerConfig from "./shared/DatePickerConfig";
import PersistedState from './hooks/PersistedState';
import { Constantes } from './shared/Constantes';
import User from './account/User';
import Loader from "./shared/Loader";
import IntlWrapper from "./layout/IntlWrapper";
import { MainLoadingProvider } from './shared/LoadingContext';
import Login from './account/Login';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './layout/Header';
import RoutesComponent from './layout/RoutesComponent';

moment.locale('es');
addLocale('es', datePickerConfig);

function App() {
  const [usuario] = PersistedState(Constantes.userStorage, new User());

  return (
    <IntlWrapper>
      <MainLoadingProvider>
        <Loader />
        {usuario?.isSuccess ?
          <Router>
            <Header />
            <RoutesComponent menu={usuario?.menu ?? []} permisosEspeciales={usuario?.permisosEspeciales ?? []} />
          </Router>
          :
          <Login />
        }
      </MainLoadingProvider>
    </IntlWrapper>
  );
}

export default App;
