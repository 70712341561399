import Swal from "sweetalert2";

function Confirm(msg) {
    return new Promise((response) => {
        Swal.fire({
            titleText: '¡Atención!',
            text: msg,
            icon: 'question',
            customClass: {
                container: 'my-swal'
            },
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            response(result.isConfirmed ?? false);
        });
    });
};

function Warning(msg) {
    Swal.fire({
        titleText: '¡Aviso!',
        text: msg,
        icon: 'warning',
        customClass: {
            container: 'my-swal'
        }
    }).then();
};

function Info(msg) {
    Swal.fire({
        titleText: '¡Información!',
        text: msg,
        icon: 'info',
        customClass: {
            container: 'my-swal'
        }
    }).then();
};

const Msg = {
    Confirm,
    Info,
    Warning
};

export default Msg;