import ServiceFactory from "../ServiceFactory";
const controller = "/user";

const UsuarioAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        getAll: async (params) => service.getQuery(`${controller}`, params),
        get: async (id) => service.get(`${controller}/${id}`),
        save: async (params) => service.post(`${controller}`, params),
        new: async () => service.get(`${controller}/new`)
    }
};

export default UsuarioAPI;