import React, { useEffect, useRef, useState } from "react";
import { LoadingState } from "./LoadingContext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

const Loader = () => {
    const { show, toast } = LoadingState();
    const [zIndex, setZIndex] = useState(1000);
    const toastBR = useRef(null);

    const findZindex = () => {
        return Math.max(
            ...Array.from(document.querySelectorAll('body *'), el =>
              parseFloat(window.getComputedStyle(el).zIndex),
            ).filter(zIndex => !Number.isNaN(zIndex)),
            0,
          );
    };

    useEffect(() => {
        if (show > 0) {
            setZIndex(findZindex() + 1);
        }
    }, [show]);

    useEffect(() => {
        if (toast.counter > 0) {
            toastBR.current.show({ severity: 'success', summary: 'Operacion Exitosa', detail: 'Operacion realizada correctamente.', life: 3000 });
        }
    }, [toast]);

    return (
        <>
            {show > 0 &&
                <div id="main-overlay-panel" style={{ zIndex: zIndex }}>
                    <div className="loading-overlay" style={{ zIndex: zIndex + 1 }}>
                    </div>
                    <div id="main-loading-container" className="loading-container" style={{ zIndex: zIndex + 1 }}>
                        <h2>Cargando...</h2>
                        <div>
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            }
            <Toast ref={toastBR} position="top-right" />
        </>
    );
}

export default Loader;