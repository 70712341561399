import { produce } from "immer";
import React, { useState } from "react";
import TextBox from "../../shared/controls/TextBox";
import { Button } from "primereact/button";

const defaultFilter = {
    item: '',
    model: ''
};

const ItemFiltro = ({ filtro, onChange, onSearch }) => {
    const [filter, setFilter] = useState(defaultFilter);

    const actualizar = (value, name) => {
        const newFilter = produce(filter, draft => {
            draft[name] = value;
        });

        setFilter(newFilter);

        if (value) {
            const newValue = produce(filtro, draft => {
                draft[name] = value;
            });

            onChange(newValue);
        }
        else {
            let newFiltro = { ...filtro };
            delete newFiltro[name];
            onChange({ ...newFiltro });
        }
    };

    const limpiar = () => {
        setFilter({ ...defaultFilter });
        const filtroInicial = {
            item: '',
            model: ''
        };

        onChange({ ...filtroInicial })
    }

    return (
        <div className="row">
            <TextBox name={'item'} value={filtro?.item} lg={3} md={3} sm={12} maxLength={10}
                onChange={actualizar} placeholder={'Costco Item'} />
            <TextBox name={'model'} value={filtro?.model} lg={3} md={3} sm={12} maxLength={15}
                onChange={actualizar} placeholder={'SAP Model'} />
            <div className="form-group col-lg-6 col-md-6 col-sm-12" style={{ textAlign: 'end' }}>
                <Button icon="pi pi-filter" className="m-1 p-button-secondary" onClick={limpiar} />
                <Button icon="pi pi-search" className="m-1" onClick={onSearch} />
            </div>
        </div>
    );
};

export default ItemFiltro;