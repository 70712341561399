import { Accordion, AccordionTab } from "primereact/accordion";
import TextBox from "../shared/controls/TextBox";
import { useState } from "react";
import CalendarBox from "../shared/controls/CalendarBox";

const Detail = ({ entity, actualizar }) => {
    const [active] = useState(null);

    return (
        <>
            {entity?.purchaseOrderDetails?.map((item, index) => {
                return (
                    <Accordion multiple activeIndex={active} key={index} style={{ marginTop: "1%" }}>
                        <AccordionTab header={`${item.rowNumber} - ${item.vendorItem.description}`}>
                            <div className="row" key={index}>
                                <TextBox label={'Row Number'} name={'rowNumber'} value={item?.rowNumber} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Quantity'} name={'quantity'} value={item?.quantity} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Remaining Quantity'} name={'remainingQuantity'} value={item?.remainingQuantity} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Shipped Quantity'} name={'shippedQuantity'} value={item?.shippedQuantity} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <CalendarBox label={'Estimated Date'} name={'estimatedDate'} value={item?.estimatedDate} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <CalendarBox label={'Expected Date'} name={'expectedDate'} value={item?.expectedDate} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <CalendarBox label={'Shipping Deadline'} name={'shippingDeadline'} value={item?.shippingDeadline} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Status'} name={'status'} value={item?.status} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Exception'} name={'exception'} value={item?.exception} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <h5 className="mt-3">Vendor Item</h5>
                                <TextBox label={'Retailer SKU'} name={'retailerSKU'} value={item?.vendorItem?.retailerSKU} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Vendor SKU'} name={'vendorSKU'} value={item?.vendorItem?.vendorSKU} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Description'} name={'description'} value={item?.vendorItem?.description} lg={12} md={12} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Package Qty'} name={'packageQty'} value={item?.vendorItem?.packageQty} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Status'} name={'status'} value={item?.vendorItem?.status} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                                <TextBox label={'Shipping Category'} name={'shippingCategory'} value={item?.vendorItem?.shippingCategory} lg={6} md={6} sm={12}
                                    onChange={actualizar} requerido={false} disabled={true} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                )
            })}
        </>
    );
}

export default Detail;