export const Constantes = {
    userStorage: 'userCostcoIntegracion',
    emptyMessage: "Sin datos para mostrar",
    paginatorTemplate: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
    rowsPerPageOptions: [10, 25, 50],
    menu: {
        Catalogs: 1,
        Commercial: 2,
        Profiles: 12,
        Items: 13,
        PurchaseOrders: 21,
        Users: 11
    },
    status: {
        New: 101,
        Acknowledged: 102,
        Printed: 103,
        PartiallyShipped: 104,
        Shipped: 105
    }
};