import ServiceFactory from "../ServiceFactory";
const controller = "/purchaseOrder";
const controllerFile = "/purchaseOrderFile";

const PurchaseOrderAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    const serviceSap = ServiceFactory({ uri: process.env.REACT_APP_API_SAP });
    return {
        getAll: async (params) => service.getQuery(`${controller}`, params),
        get: async (id) => service.get(`${controller}/${id}`),
        send: async (id) => serviceSap.post(`${controller}/send/${id}`, {}),
        reSincronizar: async () => service.post(`${controllerFile}/re-sincronizar`, {}),
        acknowledgeOrder: async (id) => service.post(`${controller}/acknowledgeOrder/${id}`, {}),
        printed: async (filename, id) => service.exportPdf(`${controller}/downloadPackingSlip`, filename, id),
        shipments: async (id, params) => service.put(`${controller}/shipments/${id}`, params),
        export: async (params, filename) => service.export(`${controller}/exportar`, filename, params)
    }
};

export default PurchaseOrderAPI;