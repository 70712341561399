import Swal from "sweetalert2";
import { LoadingDispatch } from "../shared/LoadingContext.js";
import PersistedState from "../hooks/PersistedState";
import { Constantes } from "../shared/Constantes.js";
import User from "../account/User.js"

function ServiceFactory(optios = {}) {
    const dispatch = LoadingDispatch();
    const urlBase = (optios && optios.uri) || process.env.REACT_APP_API_URL;
    const [usuario,] = PersistedState(Constantes.userStorage, new User());
    const token = 'Bearer ' + usuario?.token;

    const showLoading = (config) => {
        if (!(config?.showLoading ?? true))
            return;

        dispatch({ type: 'show' });
    };

    const hideLoading = (config) => {
        if (!(config?.showLoading ?? true))
            return;

        dispatch({ type: 'hide' });
    };

    const showSuccess = (config) => {
        if (!(config?.showSuccess ?? true))
            return;

        dispatch({ type: 'toast', message: 'La operación terminó correctamente' });
    };

    const showWarning = (msg) => {
        Swal.fire({
            titleText: '¡Aviso!',
            text: msg,
            type: 'warning'
        }).then();
    };

    const showResponse = (response, config) => {
        if (response?.statusCode === 200) {
            showSuccess(config);
        }
        else {
            showWarning(response?.value);
        }
    };

    const service = {
        get: async (urlAPI) => {
            showLoading();
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    hideLoading();
                    return Promise.reject(error);
                });

            hideLoading();
            return result;
        },
        getQuery: async (urlAPI, params) => {
            showLoading();
            const api = `${urlBase}${urlAPI}?${new URLSearchParams(params)}`;

            const result = await fetch(api, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    hideLoading();
                    return Promise.reject(error);
                });

            hideLoading();
            return result;
        },
        post: async (urlAPI, params, config) => {
            showLoading(config);
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    showResponse(responseJson, config);
                    return responseJson;
                })
                .catch((error) => {
                    hideLoading(config);
                    showWarning(error);
                    return Promise.reject(error);
                });

            hideLoading(config);
            return result;
        },
        put: async (urlAPI, params) => {
            showLoading();
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'PUT',
                body: JSON.stringify(params),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    hideLoading();
                    return Promise.reject(error);
                });

            hideLoading();
            return result;
        },
        delete: async (urlAPI) => {
            showLoading();
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'DELETE',
                headers: {
                    Authorization: token
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    hideLoading();
                    return Promise.reject(error);
                });

            hideLoading();
            return result;
        },
        upload: async (urlAPI, formData) => {
            showLoading();
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'POST',
                headers: {
                    Authorization: token
                },
                body: formData
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    hideLoading();
                    return error;
                });

            hideLoading();
            return result;
        },
        send: async (urlAPI, params) => {
            const result = await fetch(`${urlBase}${urlAPI}`, {
                method: 'POST',
                headers: {
                    Authorization: token
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    return responseJson;
                })
                .catch((error) => {
                    return error;
                });

            return result;
        },
        export: async (urlAPI, fileName, params) => {
            showLoading();
            const api = `${urlBase}${urlAPI}?${new URLSearchParams(params)}`;

            const result = await fetch(api, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    Authorization: token
                }
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${fileName}.xlsx`; // Change the filename as needed
                    a.click();

                    // Cleanup the URL object
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    hideLoading();
                    return Promise.reject(error);
                });

            hideLoading();
            return result;
        },
        exportPdf: async (urlAPI, fileName, id) => {
            showLoading();
            try {
                const api = `${urlBase}${urlAPI}/${id}`;
                const response = await fetch(api, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf',
                        Authorization: token
                    }
                });

                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${fileName}.pdf`; // Change the filename as needed
                    a.click();
                    // Cleanup the URL object
                    window.URL.revokeObjectURL(url);
                    hideLoading();                    
                }
                else {
                    showWarning("Error al obtener los datos");
                    hideLoading();                    
                }
            }
            catch (error) {
                hideLoading();
                return Promise.reject(error);
            };
        }
    };

    return service;
};

export default ServiceFactory;