import ServiceFactory from "../ServiceFactory";
const controller = "/account";

const SeguridadAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        login: async (params) => service.post(`${controller}/login`, params)
    }
};

export default SeguridadAPI;