import React, { useState } from "react";
import Spanish from "../lang/es.json";
import { IntlProvider } from "react-intl";

const lang = Spanish;

const IntlWrapper = (props) => {
    const [locale,] = useState('es');
    const [msg,] = useState(lang);

    return (
        <IntlProvider messages={msg} locale={locale}>
            {props.children}
        </IntlProvider>
    );
};

export default IntlWrapper;