import React from "react";

const Welcome = () => {
    return (
        <div className="container-fluid">

        </div>
    );
};

export default Welcome;