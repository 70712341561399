/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import UsuarioAPI from "../../api/catalogs/UsuarioAPI";
import FormReducer from "../../shared/components/FormReducer";
import { produce } from "immer";
import TextBox from "../../shared/controls/TextBox";
import { Button } from "primereact/button";
import Switch from "../../shared/controls/Switch";
import UsuarioValidacion from "./UsuarioValidacion";

const UsuarioDetalle = ({ id, onClose, puedeEditar, onSave }) => {
    const api = UsuarioAPI();
    const defaultEntity = () => ({
        entity: {
            id: 0,
            correo: '',
            nombre: '',
            apellido: '',
            paswword: '',
            activo: true,
            perfiles: []
        }
    });
    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);
    const [enviado, setEnviado] = useState(false);

    const cargar = async () => {
        const result = await api.get(id);
        dispatch({ type: 'update', value: result });
    };

    const nuevo = async () => {
        const result = await api.new();
        dispatch({ type: 'update', value: result });
    };

    useEffect(() => {
        if (id > 0) {
            cargar().then();
        }
        else {
            nuevo().then();
        }
    }, []);

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });
    };

    const actualizarPerfil = (value, name, index) => {
        const newList = produce(entity.perfiles, draft => {
            draft[index][name] = value;
        });

        actualizar(newList, 'perfiles');
    };

    const guardar = async () => {
        setEnviado(true);
        var result = false;
        if (entity.id > 0)
            result = await UsuarioValidacion.actualizar.isValid(entity);
        else
            result = await UsuarioValidacion.nuevo.isValid(entity);

        if (!result)
            return;

        await api.save(entity);
        onSave();
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <h5>{id > 0 ? 'Edicion de usuario' : 'Alta de usuario'}</h5>
                <hr />
                <TextBox label={'Nombre'} name={'nombre'} value={entity?.nombre} lg={6} md={6} sm={12}
                    onChange={actualizar} requerido={enviado} disabled={!puedeEditar} />
                <TextBox label={'Apellidos'} name={'apellido'} value={entity?.apellido} lg={6} md={6} sm={12}
                    onChange={actualizar} requerido={enviado} disabled={!puedeEditar} />
                <TextBox label={'Usuario'} name={'correo'} value={entity?.correo} lg={6} md={6} sm={12}
                    onChange={actualizar} requerido={enviado} disabled={!puedeEditar} type={'email'} />
                <TextBox label={'Contraseña'} name={'password'} value={entity?.paswword} lg={6} md={6} sm={12} type={'password'}
                    onChange={actualizar} requerido={enviado} disabled={!puedeEditar} />
                <Switch label={'Activo'} name={'activo'} value={entity?.activo}
                    onChange={actualizar} lg={6} md={6} sm={12} css={'m-2'} disabled={!puedeEditar} />
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                    <table className="table table-hoover">
                        <thead>
                            <tr>
                                <th className="center">Perfil</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {entity.perfiles?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.nombre}</td>
                                        <td>
                                            <Switch name={'seleccionado'} value={item?.seleccionado} disabled={!puedeEditar}
                                                onChange={(value, name) => actualizarPerfil(value, name, index)} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <footer className="row py-4 mt-5">
                    <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                        {puedeEditar &&
                            < Button label="Guardar" className="p-button-outlined m-1" onClick={guardar} />
                        }
                        <Button label="Cerrar" className="p-button-secondary p-button-outlined m-1" onClick={onClose} />
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default UsuarioDetalle;