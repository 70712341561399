/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import CatalogoAPI from "../../api/catalogs/CatalogoAPI";

const ComboBox = ({ name, label, value, onChange, lg, md, sm, opciones, requerido, disabled, placeholder, filter = true, showClear = true, showFilterClear = true, nameLista, param }) => {
    const api = CatalogoAPI();
    const [lista, setLista] = useState([]);
    const change = (e) => {
        e.preventDefault();
        const value = e.value;
        const name = e.target.id;
        onChange(value, name);
    };

    const load = async () => {
        if ((param ?? null) == null) {
            const result = await api.lista(nameLista);
            setLista(result);
        }
        else {
            const result = await api.listafiltrada(param, nameLista);
            console.log(result);
            setLista(result);
        }
    };

    useEffect(() => {
        if ((nameLista ?? '') !== '') {
            load().then();
        }
        else {
            setLista(opciones);
        }
    }, [nameLista, param]);

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} mt-2`}>
            <div className="form-group d-flex flex-column">
                {label &&
                    <label htmlFor={name} className='etiquetas'>
                        {requerido &&
                            <span style={{ color: 'red' }}>* </span>
                        }
                        {label}
                    </label>
                }
                <Dropdown name={name} value={value} id={name} options={lista} filter={filter} showClear={showClear} showFilterClear={showFilterClear}
                    optionLabel={'nombre'} optionValue={'id'} placeholder={placeholder}
                    onChange={change} className={requerido && ((value ?? null) === null) ? 'p-invalid block' : ''}
                    disabled={disabled} emptyMessage="Sin información para mostrar." />
            </div>
        </div>
    );
};

export default ComboBox;