import { Route, Routes } from "react-router-dom";
import Welcome from "../shared/Welcome";
import Usuario from "../catalogs/users/Usuarios";
import { Constantes } from "../shared/Constantes";
import Perfil from "../catalogs/profiles/Perfil";
import PurchaseOrder from "../orders/PurchaseOrder";
import Item from "../catalogs/items/Item";

const RoutesComponent = ({ menu, permisosEspeciales = [] }) => {

    const tienePermiso = (id) => {
        return menu.some(m => m.id === id);
    };

    const permisoEscritura = (id) => {
        return menu.some(m => m.id === id && m.escritura)
    };

    return (
        <Routes>
            <Route exact path='/' element={<Welcome />} />
            {tienePermiso(Constantes.menu.Users) &&
                <Route exact path='/catalogs/users' element={<Usuario puedeEditar={permisoEscritura(Constantes.menu.Users)} />} />
            }

            {tienePermiso(Constantes.menu.Profiles) &&
                <Route exact path='/catalogs/profiles' element={<Perfil puedeEditar={permisoEscritura(Constantes.menu.Profiles)} />} />
            }

            {tienePermiso(Constantes.menu.Items) &&
                <Route exact path='/catalogs/items' element={<Item puedeEditar={permisoEscritura(Constantes.menu.Items)} />} />
            }

            {tienePermiso(Constantes.menu.PurchaseOrders) &&
                <Route exact path='/commercial/purchaseOrders' element={<PurchaseOrder puedeEditar={permisoEscritura(Constantes.menu.PurchaseOrders)} />} />
            }
        </Routes>
    );
};

export default RoutesComponent;