import React from "react";
import { InputSwitch } from 'primereact/inputswitch';

const Switch = ({ name, label, value, onChange, lg, md, sm, requerido, disabled }) => {
    const change = (e) => {
        const value = e.value;
        onChange(value, name);
    };

    return (
        <div className={`col-lg-${lg} col-md-${md} col-sm-${sm}`}>
            {label ?
                <div className="form-group d-flex flex-row mt-5">
                    <InputSwitch name={name} value={value} className={requerido && ((value ?? '') === '' || value === null) ? 'p-invalid block' : ''}
                        onChange={change} checked={value} disabled={disabled} /><label htmlFor={name} style={{ paddingLeft: 2 }}>{label}</label>
                </div>
                :
                <div className="form-group d-flex flex-row">
                    <InputSwitch name={name} value={value} className={requerido && ((value ?? '') === '' || value === null) ? 'p-invalid block' : ''}
                        onChange={change} checked={value} disabled={disabled} />
                </div>
            }

        </div>
    );
};

export default Switch;