/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PersistedState from "../hooks/PersistedState";
import { Constantes } from "../shared/Constantes";
import User from "../account/User";
import { useNavigate } from "react-router-dom";
import Icono from "../images/carrier-notificaciones.png";
import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import { BreadCrumb } from "primereact/breadcrumb";

const Header = () => {
    const [usuario, setUsuario] = PersistedState(Constantes.userStorage, new User());
    const [items, setItems] = useState([]);
    const [itemNav, setITemNav] = useState([]);
    const [home, setHome] = useState();
    let navigate = useNavigate();

    const verNavegacion = (element, submenu) => {
        navigate(submenu.ruta);
        setHome({ icon: element.icono });
        setITemNav([{ label: element.nombre }, { label: submenu.nombre }]);
    };

    useEffect(() => {
        let menuPadre = usuario?.menu?.filter(m => m.menuPadreId === 0);
        let menuHijos = usuario?.menu?.filter(m => m.menuPadreId !== 0);

        let newItems = [];

        menuPadre.forEach(element => {
            let newList = [];
            let hijos = menuHijos.filter(h => h.menuPadreId === element.id);
            hijos.forEach(h => {
                newList.push({
                    label: h.nombre,
                    icon: h.icono,
                    command: () => {
                        verNavegacion(element, h)
                    }
                });
            });

            let newItem = {
                label: element.nombre,
                icon: element.icono,
                items: newList
            }

            newItems.push(newItem);
        });

        setItems(newItems);
    }, usuario.menu);

    const onLogout = () => {
        setUsuario(new User());
        window.location.reload();
    };

    const logo = () => (
        <a href="/costco/#" style={{ textDecoration: 'none' }}>
            <img alt="Artonik" src={Icono} style={{ height: 35 }} />
        </a>
    );

    const button = () => (
        <a href="/costco/#" style={{ textDecoration: 'none' }}>
            <Button icon="pi pi-power-off" onClick={onLogout} title="Salir" text />
        </a>
    );

    return (
        <header>
            <Menubar model={items} start={logo} end={button} />
            <BreadCrumb model={itemNav} home={home} />
        </header>
    );
};

export default Header;