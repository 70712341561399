/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ItemAPI from "../../api/catalogs/ItemAPI";
import { Button } from "primereact/button";
import ItemFiltro from "./ItemFiltro";
import { DataTable } from "primereact/datatable";
import { Constantes } from "../../shared/Constantes";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import ItemDetalle from "./ItemDetalle";

const defaultFilter = {
    item: "",
    model: ""
};

const Item = ({ puedeEditar }) => {
    const [filtro, setFiltro] = useState(defaultFilter);
    const [datos, setDatos] = useState([]);
    const api = ItemAPI();
    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(0);

    const buscar = async () => {
        const result = await api.getAll(filtro);
        setDatos(result)
    };

    useEffect(() => {
        buscar().then();
    }, []);

    const verDetalle = (id) => {
        setId(id);
        setVisible(true);
    };

    const onSave = async () => {
        setVisible(false);
        await buscar();
    };

    const headerButton = () => (
        <>
            {puedeEditar &&
                <Button type="button" icon="pi pi-plus" onClick={() => verDetalle(0)} text />
            }
        </>
    );

    const editButton = (item) => (
        <Button type="button" icon={`${puedeEditar ? 'pi pi-pencil' : 'pi pi-eye'}`} onClick={() => verDetalle(item.id)} text />
    );

    return (
        <div className="container-fluid ">
            <ItemFiltro filtro={filtro} onChange={setFiltro} onSearch={buscar} />
            <DataTable value={datos} paginator={true}
                rows={10} emptyMessage={Constantes.emptyMessage}
                paginatorTemplate={Constantes.paginatorTemplate}
                rowsPerPageOptions={Constantes.rowsPerPageOptions}>
                <Column body={editButton} header={headerButton} />
                <Column field="item" header="Costco Item" headerClassName="center" />
                <Column field="model" header="SAP Model" headerClassName="center" />
            </DataTable>
            <Sidebar visible={visible} position="right" className="p-sidebar-md" onHide={() => setVisible(false)} dismissable={false}>
                <ItemDetalle id={id} onClose={() => setVisible(false)} puedeEditar={puedeEditar} onSave={onSave} />
            </Sidebar>
        </div>
    );
};

export default Item;