import * as yup from "yup";

const actualizar = yup.object().shape({
    correo: yup.string().required(),
    nombre: yup.string().required(),
    apellido: yup.string().required(),
});

const nuevo = yup.object().shape({
    correo: yup.string().required(),
    nombre: yup.string().required(),
    apellido: yup.string().required(),
    password: yup.mixed().required()
});

const UsuarioValidacion = {
    actualizar,
    nuevo
};

export default UsuarioValidacion;