import ServiceFactory from "../ServiceFactory";
const controller = "/catalog";

const CatalogoAPI = () => {
    const service = ServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        lista: async (catalogo) => service.get(`${controller}/${catalogo}`),
        listafiltrada: async (id, catalogo) => service.get(`${controller}/${catalogo}/${id}`)
    }
};

export default CatalogoAPI;