import moment from "moment";
import React, { useState } from "react";
import TextBox from "../shared/controls/TextBox";
import CalendarRangeBox from "../shared/controls/CalendarRangeBox";
import { produce } from "immer";
import { Button } from "primereact/button";
import ComboBox from "../shared/controls/ComboBox";

const fechaInicio = new Date();
fechaInicio.setDate(fechaInicio.getDate() - 15);

const defaultFilter = {
    fechas: [fechaInicio, new Date()],
    poNumber: "",
    estatusId: 0
}

const PurchaseOrderFilter = ({ filtro, onChange, onSearch, onReload, onExport }) => {
    const [filter, setFilter] = useState(defaultFilter);

    const actualizar = (value, name) => {
        const newFilter = produce(filter, draft => {
            draft[name] = value;
        });

        setFilter(newFilter);

        if (value) {
            const newValue = produce(filtro, draft => {
                if (name === 'fechas') {
                    draft[name] = [moment(value[0]).format('yyyy-MM-DD'), moment(value[1]).format('yyyy-MM-DD')];
                }
                else {
                    draft[name] = value;
                }
            });

            onChange(newValue);
        }
        else {
            let newFiltro = { ...filtro };
            delete newFiltro[name];
            onChange({ ...newFiltro });
        }
    };

    const limpiar = () => {
        const fechaInicio = new Date();
        const filtroInicial = {
            fechas: [moment(fechaInicio.setDate(fechaInicio.getDate() - 15)).format('yyyy-MM-DD'), moment(new Date()).format('yyyy-MM-DD')],
            poNumber: "",
            estatusId: 0
        }

        setFilter(filtroInicial);

        onChange({ ...filtroInicial })
    };

    return (
        <div className="row">
            <TextBox name={'poNumber'} value={filter?.poNumber} lg={3} md={3} sm={12}
                onChange={actualizar} placeholder={'Customer Order'} maxLength={20} />
            <ComboBox name={'estatusId'} value={filter?.estatusId} lg={3} md={3} sm={12} showClear={true} nameLista={'estatus'}
                onChange={actualizar} placeholder={'Estatus'} filter={true} showFilterClear={true} />
            <CalendarRangeBox name={'fechas'} value={filter?.fechas} lg={3} md={3} sm={12}
                onChange={actualizar} placeholder={'Shipping Deadline'} />
            <div className="form-group col-lg-3 col-md-3 col-sm-12" style={{ textAlign: 'end' }}>
                {/* {datos.some(d => d.estatusId === Constantes.status.New) &&

                } */}
                <Button label="Obtener ordenes" className="p-button-success p-button-outlined m-1"
                    onClick={onReload} />
                <Button icon="pi pi-filter" className="m-1 p-button-secondary" onClick={limpiar} />
                <Button icon="pi pi-file-excel" className="m-1 p-button-success" onClick={onExport} />
                <Button icon="pi pi-search" className="m-1" onClick={onSearch} />
            </div>
        </div>
    );
};

export default PurchaseOrderFilter;