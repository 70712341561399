import React, { useState } from "react";
import { Button } from "primereact/button";
import TextBox from "../../shared/controls/TextBox";
import ComboBox from "../../shared/controls/ComboBox";
import { produce } from "immer";

const defaultFilter={};

const UsuarioFiltro = ({ filtro, onChange, onSearch }) => {
    const [filter, setFilter] = useState(defaultFilter);

    const actualizar = (value, name) => {
        const newFilter = produce(filter, draft => {
            draft[name] = value;
        });

        setFilter(newFilter);

        if (value) {
            const newValue = produce(filtro, draft => {
                draft[name] = value;
            });

            onChange(newValue);
        }
        else {
            let newFiltro = { ...filtro };
            delete newFiltro[name];
            onChange({ ...newFiltro });
        }
    };

    const limpiar = () => {
        setFilter({ ...defaultFilter, folio: '', proyecto: '' });
        const filtroInicial = {};

        onChange({ ...filtroInicial })
    };

    return (
        <div className="row">
            <TextBox name={'usuario'} value={filtro?.usuario} lg={3} md={3} sm={12}
                onChange={actualizar} placeholder={'Usuario'} />
            <TextBox name={'nombres'} value={filtro?.nombres} lg={3} md={3} sm={12}
                onChange={actualizar} placeholder={'Nombre(s)'} />
            <TextBox name={'apellidos'} value={filtro?.apellidos} lg={3} md={3} sm={12}
                onChange={actualizar} placeholder={'Apellido(s)'} />
            <ComboBox name={'perfilId'} value={filtro?.perfilId} lg={3} md={3} sm={12}
                nameLista={'perfiles'} onChange={actualizar} placeholder={'Perfil'} />
            <div className="form-group col-lg-12 col-md-12 col-sm-12" style={{ textAlign: 'end' }}>
                <Button icon="pi pi-filter" className="m-1 p-button-secondary" onClick={limpiar} />
                <Button icon="pi pi-search" className="m-1" onClick={onSearch} />
            </div>
        </div>
    );
};

export default UsuarioFiltro;