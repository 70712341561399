/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import PurchaseOrderAPI from "../api/orders/PurchaseOrderAPI";
import FormReducer from "../shared/components/FormReducer";
import { TabPanel, TabView } from "primereact/tabview";
import General from "./General";
import Customer from "./Customer";
import Detail from "./Detail";
import { Button } from "primereact/button";
import { Constantes } from "../shared/Constantes";

const defaultEntity = () => ({
    entity: {
        id: 0
    }
});

const PurchaseOrderDetail = ({ id, puedeEditar, onClose, onSave }) => {
    const api = PurchaseOrderAPI();
    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);
    //const [enviado, setEnviado] = useState(false);

    const cargar = async () => {
        const result = await api.get(id);
        dispatch({ type: 'update', value: result });
    };

    useEffect(() => {
        cargar().then(id);
    }, []);

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });
    };

    const print = async () => {
        await api.printed(entity?.poNumber, id);
        onClose();
        onSave();
    };

    const shipment = async () => {
        await api.shipments(id, entity);
        onClose();
        onSave();
    };

    return (
        <div className="container-fluid">
            <TabView activeIndex={0}>
                <TabPanel header="General">
                    <General entity={entity} actualizar={actualizar} />
                </TabPanel>
                <TabPanel header="Customer">
                    <Customer entity={entity} name={'customer'} actualizar={actualizar} />
                </TabPanel>
                <TabPanel header="Ship To">
                    <Customer entity={entity} name={'shipTo'} actualizar={actualizar} />
                </TabPanel>
                <TabPanel header="Detail">
                    <Detail entity={entity} actualizar={actualizar} />
                </TabPanel>
            </TabView>
            <footer className="form-row py-4 mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                    {puedeEditar &&
                        <>
                            {entity?.estatusId !== Constantes.status.New &&
                                <Button label="Printed" className="p-button-outlined m-1" onClick={print} />
                            }

                            {/* {entity?.estatusId === Constantes.status.Printed &&
                                <Button label="Partially Shipped" className="p-button-outlined m-1" onClick={print} />
                            } */}

                            {(entity?.estatusId === Constantes.status.Printed || entity?.estatusId === Constantes.status.PartiallyShipped) &&
                                <Button label="Shipped" className="p-button-outlined m-1" onClick={shipment} />
                            }
                        </>
                    }
                    <Button label="Cerrar" className="p-button-secondary p-button-outlined m-1" onClick={onClose} />
                </div>
            </footer>
        </div>
    );
};

export default PurchaseOrderDetail;
