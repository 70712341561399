import React, { useState } from "react";
import LoginValidacion from "./LoginValidacion";
import TextBox from "../shared/controls/TextBox";
import { Button } from "primereact/button";
import User from "./User";
import SeguridadAPI from "../api/account/SeguridadAPI";
import { Constantes } from "../shared/Constantes";
import PersistedState from "../hooks/PersistedState";
import { produce } from "immer";

const Login = () => {
    const api = SeguridadAPI();
    const [login, setLogin] = useState({ username: '', password: '' });
    const [enviado, setEnviado] = useState(false);
    const [, setUser] = PersistedState(Constantes.userStorage, new User());

    const actualizar = (value, name) => {
        const newLogin = produce(login, draft => {
            draft[name] = value;
        });

        setLogin(newLogin);
    };

    const onLogin = async (e) => {
        e.preventDefault();
        setEnviado(true);
        const validacion = await LoginValidacion.isValid(login);
        if (validacion) {
            const result = await api.login(login);
            if (result?.value?.token) {
                setUser(result.value);
                window.location.reload();
            }
            setEnviado(false);
        }
        else {
            setEnviado(false);
        }
    };

    const onEnter = async (event) => {
        if (event.charCode === 13) {
            await onLogin();
        }
    };

    return (
        <div className="login">
            <div className="container-fluid">
                <div className="row" style={{ height: '25vh' }}></div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                        <img src={require('../images/logo_carrier.jpg')} alt="Carrier Enterprise" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12"></div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <form className="row" onKeyUp={onEnter}>
                            <TextBox label={'User'} name="username" value={login?.username} requerido={enviado}
                                onChange={actualizar} type="text" lg={12} md={12} sm={12} />
                            <TextBox label={'Pawword'} name="password" value={login?.password} requerido={enviado}
                                onChange={actualizar} type="password" lg={12} md={12} sm={12} />
                            <div className="col-lg-12 col-md-12 col-sm-12 mt-5 botones" style={{ textAlign: 'end' }}>
                                <Button label="Login" icon="pi pi-sign-in" className="btn btn-primary"
                                    onClick={onLogin} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;