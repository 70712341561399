/* eslint-disable react-hooks/exhaustive-deps */
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Constantes } from "../shared/Constantes";
import PurchaseOrderAPI from "../api/orders/PurchaseOrderAPI";
import moment from "moment";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import PurchaseOrderDetail from "./PurchaseOrderDetail";
import PurchaseOrderFilter from "./PurchaseOrderFilter";
import Msg from "../shared/controls/MessageBox";

const fechaInicio = new Date();
fechaInicio.setDate(fechaInicio.getDate() - 30);

const defaultFilter = {
    fechas: [moment(fechaInicio).format('yyyy-MM-DD'), moment(new Date()).format('yyyy-MM-DD')]
};

const PurchaseOrder = ({ puedeEditar }) => {
    const [datos, setDatos] = useState([]);
    const api = PurchaseOrderAPI();
    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(0);
    const [filtro, setFiltro] = useState(defaultFilter);

    const buscar = async () => {
        const result = await api.getAll(filtro);
        setDatos(result);
    };

    useEffect(() => {
        buscar().then();
    }, []);

    const templateDate = (item, name) => (
        <>
            {moment(item[name]).format("DD/MM/YYYY")}
        </>
    );

    const templateBool = (item) => (
        <>
            {item?.enviadoSAP ?
                <span>SI</span>
                :
                <Button type="button" icon={'pi pi-upload'} onClick={() => enviarSap(item.id)} text />
            }
        </>
    );

    const verDetalle = (id) => {
        setId(id);
        setVisible(true);
    };

    const enviar = async () => {
        const result = await Msg.Confirm("¿Obtener nuevas ordenes de compra desde Costco?");
        if (result)
            await api.reSincronizar();
        await buscar();
    };

    const enviarSap = async (id) => {
        const result = await Msg.Confirm("¿Enviar orden de compra a SAP?");
        if (result)
            await api.send(id);

        await buscar();
    };

    const editButton = (item) => (
        <Button type="button" icon={`${puedeEditar ? 'pi pi-pencil' : 'pi pi-eye'}`} onClick={() => verDetalle(item.id)} text />
    );

    const enviarConfirmacion = async (id) => {
        const result = await Msg.Confirm("¿Enviar confirmación a Costco (Acknowledged)?");
        if (result)
            await api.acknowledgeOrder(id);

        await buscar();
    };

    const exportar = async () => {
        await api.export(filtro, 'Purchase_Orders');
    };

    const templateEstatus = (item) => (
        <>
            {item?.estatusId !== Constantes.status.New ?
                <span>{item?.estatus}</span>
                :
                <Button type="button" icon={'pi pi-send'} onClick={() => enviarConfirmacion(item.id)} text />
            }
        </>
    );

    return (
        <div className="container-fluid ">
            <PurchaseOrderFilter filtro={filtro} onChange={setFiltro} onSearch={buscar} onReload={enviar} onExport={exportar} />
            <DataTable value={datos} paginator={true}
                rows={10} emptyMessage={Constantes.emptyMessage}
                paginatorTemplate={Constantes.paginatorTemplate}
                rowsPerPageOptions={Constantes.rowsPerPageOptions}>
                <Column body={editButton} />
                <Column field="poNumber" header="PO Number" headerClassName="center" />
                <Column field="retailerCode" header="Retailer Code" headerClassName="center" style={{ minWidth: '150px' }} />
                <Column field="customerOrder" header="Customer Order" headerClassName="center" style={{ minWidth: '150px' }} />
                <Column field="customer" header="Customer" headerClassName="center" style={{ minWidth: '250px' }} />
                <Column field="shipTo" header="Ship To" headerClassName="center" style={{ minWidth: '250px' }} />
                <Column field="status" header="PO Status" headerClassName="center" />
                <Column field="exception" header="Exception" headerClassName="center" />
                <Column field="ecommDeliveryMethod" header="Ecomm Delivery Method" headerClassName="center" style={{ minWidth: '250px' }} />
                <Column field="ecommDate" header="Ecomm Date" headerClassName="center" body={(item) => templateDate(item, 'ecommDate')} style={{ minWidth: '150px' }} />
                <Column field="shippingDeadline" header="Shipping Deadline" headerClassName="center" body={(item) => templateDate(item, 'shippingDeadline')} style={{ minWidth: '200px' }} />
                <Column field="importTime" header="Import Time" headerClassName="center" body={(item) => templateDate(item, 'importTime')} />
                <Column field="estatus" header="Estatus" body={(item) => templateEstatus(item)} headerClassName="center" style={{ minWidth: '150px' }} />
                <Column field="enviadoSAP" header="Enviado SAP" body={(item) => templateBool(item)} headerClassName="center" style={{ minWidth: '150px' }} />
                <Column field="sapResult" header="Respuesta SAP" headerClassName="center" style={{ minWidth: '200px' }} />
            </DataTable>
            <Sidebar visible={visible} position="right" className="p-sidebar-lg" onHide={() => setVisible(false)} dismissable={false}>
                <PurchaseOrderDetail id={id} onClose={() => setVisible(false)} puedeEditar={puedeEditar} onSave={buscar} />
            </Sidebar>
        </div>
    );
};

export default PurchaseOrder;