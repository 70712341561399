/* eslint-disable react-hooks/exhaustive-deps */
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Sidebar } from "primereact/sidebar";
import { React, useEffect, useState } from "react";
import CatalogoAPI from "../../api/catalogs/CatalogoAPI";
import UsuarioFiltro from "./UsuarioFiltro";
import { Button } from "primereact/button";
import UsuarioDetalle from "./UsuarioDetalle";
import { Constantes } from "../../shared/Constantes";
import moment from "moment";
import UsuarioAPI from "../../api/catalogs/UsuarioAPI";

const Usuario = ({ puedeEditar }) => {
    const [filtro, setFiltro] = useState({});
    const obtener = CatalogoAPI();
    const api = UsuarioAPI();
    const [perfiles, setPerfiles] = useState([]);
    const [datos, setDatos] = useState([]);
    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(0);

    const listas = async () => {
        const perfil = await obtener.lista('perfiles');
        setPerfiles(perfil);
    }

    useEffect(() => {
        listas().then();
    }, []);

    const buscar = async () => {
        const result = await api.getAll(filtro);
        setDatos(result);
    }

    useEffect(() => {
        buscar();
    }, []);

    const templateActivo = (item) => (
        <>
            {item?.activo ? 'Activo' : 'Inactivo'}
        </>
    );

    const templateFecha = (item) => (
        <>
            {moment(item?.createDate).format("DD/MM/YYYY")}
        </>
    );

    const verDetalle = (id) => {
        setId(id);
        setVisible(true);
    };

    const headerButton = () => (
        <>
            {puedeEditar &&
                <Button type="button" icon="pi pi-plus" onClick={() => verDetalle(0)} text />
            }
        </>
    );

    const editButton = (item) => (
        <Button type="button" icon={`${puedeEditar ? 'pi pi-pencil' : 'pi pi-eye'}`} onClick={() => verDetalle(item.id)} text />
    );

    const onSave = async () => {
        setVisible(false);
        await buscar();
    };

    return (
        <div className="container-fluid">
            <UsuarioFiltro filtro={filtro} onChange={setFiltro} onSearch={buscar} />
            <DataTable value={datos} paginator={true}
                rows={10} emptyMessage={Constantes.emptyMessage}
                paginatorTemplate={Constantes.paginatorTemplate}
                rowsPerPageOptions={Constantes.rowsPerPageOptions}>
                <Column body={editButton} header={headerButton} />
                <Column field="fullName" header="Nombre" headerClassName="center" />
                <Column field="correo" header="Usuario" headerClassName="center" />
                <Column field="perfiles" header="Perfiles" headerClassName="center" />
                <Column field="activo" header="Estatus" headerClassName="center" body={templateActivo} />
                <Column field="auditDate" header="Fecha Creacion" headerClassName="center" body={templateFecha} />
            </DataTable>
            <Sidebar visible={visible} position="right" className="p-sidebar-lg"  onHide={() => setVisible(false)} dismissable={false}>
                <UsuarioDetalle id={id} perfiles={perfiles} onClose={() => setVisible(false)} puedeEditar={puedeEditar} onSave={onSave} />
            </Sidebar>
        </div >
    );
};

export default Usuario;