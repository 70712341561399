import React from "react";
import TextBox from "../shared/controls/TextBox";

const Customer = ({ entity, name, actualizar }) => {
    return (
        <div className="row py-1">
            <TextBox label={'Name'} name={'name'} value={entity[name].name} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Masked Name'} name={'maskedName'} value={entity[name].maskedName} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'SurName'} name={'surname'} value={entity[name].surname} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Masked SurName'} name={'maskedSurname'} value={entity[name].maskedSurname} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Customer Code'} name={'customerCode'} value={entity[name].customerCode} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Mail'} name={'mail'} value={entity[name].mail} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Phone Number'} name={'phoneNumber'} value={entity[name].phoneNumber} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <h5 className="m-2">Address</h5>
            <hr />
            <TextBox label={'Town'} name={'town'} value={entity[name]?.address?.town} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'State Or Province'} name={'stateOrProvince'} value={entity[name]?.address?.stateOrProvince} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Postal Code'} name={'postCode'} value={entity[name]?.address?.postCode} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Country'} name={'country'} value={entity[name]?.address?.country} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Additional Line 1'} name={'additionalLine1'} value={entity[name]?.address?.additionalLine1} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Additional Line 2'} name={'additionalLine2'} value={entity[name]?.address?.additionalLine2} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
            <TextBox label={'Additional Line 3'} name={'additionalLine3'} value={entity[name]?.address?.additionalLine3} lg={6} md={6} sm={12}
                onChange={actualizar} requerido={false} disabled={true} />
        </div>
    );
};

export default Customer;