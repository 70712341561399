/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import PerfilAPI from "../../api/catalogs/PerfilAPI";
import FormReducer from "../../shared/components/FormReducer";
import Switch from "../../shared/controls/Switch";
import TextBox from "../../shared/controls/TextBox";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PerfilValidacion from "./PerfilValidacion";
import { produce } from "immer";

const PerfilDetalle = ({ id, onClose, puedeEditar, onSave }) => {
    const api = PerfilAPI();
    const defaultEntity = () => ({
        entity: {
            id: 0,
            nombre: '',
            activo: true,
            root: []
        }
    });
    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);
    const [enviado, setEnviado] = useState(false);

    const cargar = async () => {
        const result = await api.get(id);
        dispatch({ type: 'update', value: result });
    };

    const nuevo = async () => {
        const result = await api.new();
        dispatch({ type: 'update', value: result });
    };

    useEffect(() => {
        if (id > 0) {
            cargar().then();
        }
        else {
            nuevo().then();
        }
    }, []);

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });
    };

    const actualizarMenu = (value, name, node) => {
        const newValue = produce(entity.root, (draft) => {
            if (node?.data.esPadre) {
                let row = draft.find(d => d.key === node?.key);
                if (row) {
                    row.data[name] = value;
                    row.children.forEach((item, index) => {
                        item.data[name] = value;
                    });
                }
            }
            else {
                draft.forEach((item, index) => {
                    let row = item.children.find(c => c.key === node?.key);
                    if (row) {
                        row.data[name] = value;
                        item.data[name] = item.children.some(i => i.data[name]);
                    }
                });
            }
        });

        actualizar(newValue, 'root');
    };

    const lecturaTemplate = (node, column) => {
        return (
            <Switch name={'lectura'} value={node?.data?.lectura} disabled={!puedeEditar}
                onChange={(value, name) => actualizarMenu(value, name, node)} />
        );
    };

    const escrituraTemplate = (node, column) => {
        return (
            <Switch name={'escritura'} value={node?.data?.escritura} disabled={!puedeEditar}
                onChange={(value, name) => actualizarMenu(value, name, node)} />
        );
    };

    const guardar = async () => {
        setEnviado(true);
        const result = PerfilValidacion.isValid(entity);

        if (!result)
            return;

        await api.save(entity);
        onSave();
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <h5>{id > 0 ? 'Editar Perfil' : 'Nuevo Perfil'}</h5>
                <hr />
                <TextBox label={'Perfil'} name={'nombre'} value={entity?.nombre} lg={6} md={6} sm={12}
                    onChange={actualizar} requerido={enviado} disabled={!puedeEditar} />
                <Switch label={'Activo'} name={'activo'} value={entity?.activo}
                    onChange={actualizar} lg={12} md={12} sm={12} css={'m-2'} disabled={!puedeEditar} />
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <TreeTable value={entity?.root}>
                        <Column field="nombre" header="menu" expander={true} />
                        <Column body={lecturaTemplate} header="Visor" />
                        <Column body={escrituraTemplate} header="Editor" />
                    </TreeTable>
                </div>
            </div>
            <footer className="form-row py-4 mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                    {puedeEditar &&
                        <Button label="Guardar" className="p-button-outlined m-1" onClick={guardar} />
                    }
                    <Button label="Cerrar" className="p-button-secondary p-button-outlined m-1" onClick={onClose} />
                </div>
            </footer>
        </div>
    );
};

export default PerfilDetalle;