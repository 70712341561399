/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import ItemAPI from "../../api/catalogs/ItemAPI";
import FormReducer from "../../shared/components/FormReducer";
import TextBox from "../../shared/controls/TextBox";
import { Button } from "primereact/button";
import ItemValidacion from "./ItemValidacion";

const defaultEntity = () => ({
    entity: {
        id: 0,
        item: "",
        model: ""
    }
});

const ItemDetalle = ({ id, onClose, puedeEditar, onSave }) => {
    const api = ItemAPI();
    const [{ entity }, dispatch] = useReducer(FormReducer, {}, defaultEntity);
    const [enviado, setEnviado] = useState(false);

    const cargar = async () => {
        const result = await api.get(id);
        dispatch({ type: 'update', value: result });
    };

    useEffect(() => {
        if (id > 0) {
            cargar().then();
        }
    }, []);

    const actualizar = (value, name) => {
        dispatch({ type: 'update', value: value, name: name });
    };

    const guardar = async () => {
        setEnviado(true);
        const result = ItemValidacion.isValid(entity);

        if (!result)
            return;

        await api.save(entity);
        onSave();
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <h5>{id > 0 ? 'Editar Item' : 'Nuevo Item'}</h5>
                <hr />
                <TextBox label={'Costco Item'} name={'item'} value={entity?.item} lg={6} md={6} sm={12}
                    onChange={actualizar} requerido={enviado} disabled={!puedeEditar} maxLength={10} />
                <TextBox label={'SAP Model'} name={'model'} value={entity?.model} lg={6} md={6} sm={12}
                    onChange={actualizar} requerido={enviado} disabled={!puedeEditar} maxLength={15} />
            </div>

            <footer className="form-row py-4 mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 botones" style={{ textAlign: 'end' }}>
                    {puedeEditar &&
                        <Button label="Guardar" className="p-button-outlined m-1" onClick={guardar} />
                    }
                    <Button label="Cerrar" className="p-button-secondary p-button-outlined m-1" onClick={onClose} />
                </div>
            </footer>
        </div>
    );
};

export default ItemDetalle;
